import { Link, useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import React from "react";

/* Hero
============================================================================= */
const Hero = () => {
	const { banner } = useStaticQuery(graphql`
		query {
			banner: file(relativePath: { eq: "heroimage.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	return (
		<div id="hero" className="bg-red-400 w-full relative">
			<div className="md:h-244 h-156 bg-black-400 w-full">
				<div className="h-full w-full absolute z-0 object-cover">
					<Image
						fluid={banner.childImageSharp.fluid}
						className="h-full w-full "
						imgStyle={{ objectFit: "cover" }}
						alt="Puppetmaster Logo"
					/>
				</div>
				<div className="container mx-auto flex flex-col items-center justify-center h-full">
					<img
						className="md:mb-32 mb-12 w-4/6 md:w-1/2 mx-auto relative z-0 object-contain"
						src={`PM_Logo.png`}
						alt="Puppetmaster Logo"
					/>
					<div>
						<div className="crimson relative z-0 text-white text-lg md:text-xl text-center mx-auto w-4/5 md:w-2/5">
							"Power is in tearing human minds to pieces and
							putting them together again in new shapes of your
							own choosing"
						</div>
						<div className="relative z-0 text-white bg-white text-center mx-auto h-1 mt-4 w-1/6"></div>
						<div className="mt-10 relative z-0 text-white text-center mx-auto md:text-xl text-lg font-semibold w-2/5">
							GEORGE ORWELL
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default Hero;
