import { Link, useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import React from "react";

/* Grid
============================================================================= */
const Grid = () => {
	const {
		one,
		two,
		three,
		four,
		five,
		six,
		seven,
		eight,
		nine,
	} = useStaticQuery(graphql`
		fragment ImageStuff on File {
			childImageSharp {
				fluid(maxWidth: 800) {
					...GatsbyImageSharpFluid
				}
			}
		}
		query {
			one: file(relativePath: { eq: "gridone.png" }) {
				...ImageStuff
			}
			two: file(relativePath: { eq: "gridtwo.png" }) {
				...ImageStuff
			}
			three: file(relativePath: { eq: "gridthree.png" }) {
				...ImageStuff
			}
			four: file(relativePath: { eq: "gridfour.png" }) {
				...ImageStuff
			}
			five: file(relativePath: { eq: "gridfive.png" }) {
				...ImageStuff
			}
			six: file(relativePath: { eq: "gridsix.png" }) {
				...ImageStuff
			}
			seven: file(relativePath: { eq: "gridseven.png" }) {
				...ImageStuff
			}
			eight: file(relativePath: { eq: "grideight.png" }) {
				...ImageStuff
			}
			nine: file(relativePath: { eq: "gridnine.png" }) {
				...ImageStuff
			}
		}
	`);

	return (
		<div className="bg-white w-full h-full px-6 py-6 md:px-0 md:py-24">
			<div className="container mx-auto md:w-3/5 h-full text-center grid grid-rows-3 grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
				<div className="">
					<Image fluid={one.childImageSharp.fluid} />
				</div>
				<div className="">
					<Image fluid={two.childImageSharp.fluid} />
				</div>
				<div className="">
					<Image fluid={three.childImageSharp.fluid} />
				</div>
				<div className="">
					<Image fluid={four.childImageSharp.fluid} />
				</div>
				<div className="">
					<Image fluid={five.childImageSharp.fluid} />
				</div>
				<div className="">
					<Image fluid={six.childImageSharp.fluid} />
				</div>
				<div className="">
					<Image fluid={seven.childImageSharp.fluid} />
				</div>
				<div className="">
					<Image fluid={eight.childImageSharp.fluid} />
				</div>
				<div className="">
					<Image fluid={nine.childImageSharp.fluid} />
				</div>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default Grid;
