import create from "zustand";

/* Wine Data
============================================================================= */

const WineData = [
	{
		title: "PINOT NOIR",
		year: "2017",
		description:
			"The colour is red gemstones and the palate an attractive bouquet of cherries, plums, and exotic, sweet spices. Red berry fruits, a silky mouthfeel, gentle acidity and elegant tannins give vibrancy of flavour and a lovely long finish to this elegant yet complex wine.",
		movieMatch: "Puppetmaster (1989) - Fantasy/Horror/Sci-Fi ",
		bookMatch: "Nineteen Eighty-Four by George Orwell - Dystopian Fiction",
		image: "PinotNoir.png",
	},
	{
		title: "PINOT GRIS",
		year: "2018",
		description:
			"A delicate Pinot Gris from the verdant and cool forest region of Manjimup. Enjoy arresting aromatics of honeysuckle and rose petal on the nose,  flavours of green skin pear, nectarine and apple provide freshness to the palate; while time on yeast lees give a textural richness to the wine. A soft acidity to finish, it is fresh, complex, varietal and delicious.",
		movieMatch: "The Masque of the Red Death (1964) - Drama/Horror",
		bookMatch: "The Name of the Rose by Umberto Eco - Italian Literature",
		image: "PinotGris.png",
	},
	{
		title: "ROSÉ",
		year: "2018",
		description:
			"A generous Margaret River Shiraz Rosé, with bright perfume of strawberry, red plum, rose and orange blossom. The taste is clean, lively and lingering, with expressions of cranberry, grapefruit, strawberry and a flicker of vanilla.",
		movieMatch: "Étoile (1989) - Fantasy/Thriller",
		bookMatch:
			"Les Fleurs du mal (Flowers of Evil) by Charles Beaudelaire - French Poetry",
		image: "NV_Rose.png",
	},
	{
		title: "PROSECCO",
		year: "2018",
		description:
			"Notes of golden delicious apples, beurre bosc pear and ginger spice greet you first, followed by flavours of crisp, tart apple flesh balanced by a honeyed and creamy richness. Gentle, textural bubbles fill the palate and beg for you to take another sip of this wonderfully sensual and flavourful wine.",
		movieMatch: "The Abyss (1989) - Adventure, Drama, Mystery",
		bookMatch:
			"Twenty Thousand Leagues Under the Sea by Jules Verne – Sci-Fi",
		image: "NV_Prosecco.png",
	},
	{
		title: "MOSCATO",
		year: "2019",
		description:
			"Full of exotic notes of guava, quince and raspberry, lightly spiced with musk, rose petal and star anise. Fresh, punchy lychee, raspberry sorbet, and pomegranate flavours provide a luscious sweetness to the palate, perfectly complimented by a zesty citrus acid crunch and light fizz.",
		movieMatch:
			"Eternal Sunshine of the Spotless Mind (2004) - Drama/Sci-Fi",
		bookMatch:
			"Time After Time By Constance O’Day-Flannery - Romance/Sci-Fi",
		image: "Moscato.png",
	},
	{
		title: "CHARDONNAY",
		year: "2018",
		description:
			"This wine is all about subtlety, complexity, nuisances of flavour, balance and engagement. Delicate, ethereal notes of citrus blossom, honeysuckle and peach fuzz mingle with undertones of crème caramel and vanilla. The palate exhibits refined fruit flavours of honey dew, citrus and fig with a sprinkle of nutmeal and an enduring, fine, mineral acidity to finish.",
		movieMatch: "2001: A Space Odyssey (1968) - Adventure/Sci-Fi",
		bookMatch:
			"I Have No Mouth and I Must Scream by Harlan Ellison – Post-Apocalyptic Sci-Fi",
		image: "Chardonnay.png",
	},
];

/* Use Selected Wine
============================================================================= */

const [useSelectedWine] = create((set) => ({
	selectedWine: 0,
	wines: WineData,
	setSelectedWine: (index) => set(() => ({ selectedWine: index })),
}));

/* Export
============================================================================= */

export default useSelectedWine;
