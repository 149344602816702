import { Link } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

/* Stockists
============================================================================= */
const Stockists = () => {
	return (
		<>
			<Helmet>
				<script>
					{`(function(s,t,o,c,k){c=s.createElement(t);c.src=o;c.async=1;k=s.getElementsByTagName(t)[0];k.parentNode.insertBefore(c,k);})(document,'script','//stockist.co/embed/v1/widget.min.js');`}
				</script>
			</Helmet>
			<div id="stockists" className="w-full orangecolour">
				<div className="py-16 container mx-auto h-full flex flex-col items-start justify-start">
					<div className="h-full w-full md:pb-0 pb-10">
						<h1
							style={{
								fontFamily: "Crimson-Bold",
							}}
							className="mb-0 md:mb-6 text-black text-3xl text-center md:text-left"
						>
							WHERE TO FIND US
						</h1>
					</div>
					<div className="h-full w-full bg-black border-black border-solid border-8 rounded-md">
						<div data-stockist-widget-tag="u6048">
							Loading store locator from{" "}
							<a href="http://stockist.co">
								Stockist store locator
							</a>
							...
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

/* Export
============================================================================= */

export default Stockists;
