import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Image from "gatsby-image";
import AboutCarousel from "./aboutCarousel";

/* About
============================================================================= */
const About = () => {
	const { one, two, three, aboutbg } = useStaticQuery(graphql`
		fragment ImageStuff on File {
			childImageSharp {
				fluid(maxWidth: 800) {
					...GatsbyImageSharpFluid
				}
			}
		}
		query {
			one: file(relativePath: { eq: "carouselone.jpg" }) {
				...ImageStuff
			}
			two: file(relativePath: { eq: "carouseltwo.jpg" }) {
				...ImageStuff
			}
			three: file(relativePath: { eq: "carouselthree.jpg" }) {
				...ImageStuff
			}
			aboutbg: file(relativePath: { eq: "aboutbg.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1500) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	return (
		<div id="about" className="relative bg-black w-full overflow-hidden">
			<div className="h-full w-full absolute z-0 object-cover">
				<Image fluid={aboutbg.childImageSharp.fluid} />
			</div>
			<img
				className="h-full w-full absolute z-0 object-cover"
				src={`262A7096_Mono@2x.jpg`}
				alt="Puppetmaster Logo"
			/>
			<div className="mx-auto relative z-0 text-center text-white py-16">
				<div
					style={{ fontFamily: "Crimson-Bold" }}
					className="md:text-5xl text-4xl relative z-0 font-bold"
				>
					ABOUT
				</div>
				<div className="bg-red-200 relative z-0 h-1 w-20 mx-auto"></div>
			</div>
			<div className="md:space-x-8 h-full container mx-auto relative flex px-10 flex-col lg:flex-row items-center justify-around w-full pb-12">
				<div className="w-full h-full pb-8 md:pb-0 md:w-3/5">
					<AboutCarousel>
						<div className="h-full w-full">
							<Image fluid={one.childImageSharp.fluid} />
						</div>
						<div className="h-full w-full">
							<Image fluid={two.childImageSharp.fluid} />
						</div>
						<div className="h-full w-full">
							<Image fluid={three.childImageSharp.fluid} />
						</div>
					</AboutCarousel>
				</div>
				<div className="text-white w-full lg:w-2/5 flex flex-col justify-between">
					<div className="text-2xl md:text-3xl pb-6 md:pb-4 orangetext font-bold">
						A Western Australian wine collection inspired by ancient
						fairy tales, dystopian novels, cinema noir and vintage
						sci-fi.
					</div>
					<div
						style={{ fontFamily: "Crimson-Regular" }}
						className="text-lg md:text-xl"
					>
						<p className="pb-4">
							Inspired by alchemists and sorcerers; our winemakers
							set out to bottle the elements. However they soon
							learned it is not them that pull the strings. It is
							the fruit, the weather; the very soil from which the
							vines grow that controlled the wine they made. Like
							marionettes on a string, guided as if by a puppet
							master, the winemaker was forced to surrender
							control and make wine as the elements demanded. They
							became the vessel through which the elements spoke,
							the conduit between the earth and the glass.
						</p>
						<p>
							Puppet Master wines, like the art on their labels,
							is a mesmerising collection dictated not by the
							winemaker but by the vines.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default About;
