import React from "react";
import { graphql } from "gatsby";
import Hero from "../components/hero";
import About from "../components/about";
import Wines from "../components/wines";
import Grid from "../components/grid";
import Stockists from "../components/stockists";
import Banner from "../components/banner";

const IndexPage = () => {
	return (
		<>
			<Hero></Hero>
			<About></About>
			<Banner></Banner>
			<Wines></Wines>
			<Banner></Banner>
			<Grid></Grid>
			<Stockists></Stockists>
		</>
	);
};

export default IndexPage;
