import { Link, useStaticQuery, graphql } from "gatsby";
import {
	CarouselProvider,
	CarouselContext,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
	DotGroup,
} from "pure-react-carousel";
import Image from "gatsby-image";
import React, { useEffect, useContext } from "react";
import useSelectedWine from "../zustand/useSelectedWine";

/* Wines
============================================================================= */
const Wines = () => {
	const { bg } = useStaticQuery(graphql`
		query {
			bg: file(relativePath: { eq: "winesbg.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	const { selectedWine, wines, setSelectedWine } = useSelectedWine();

	// Render Wines
	const renderWines = () => {
		return wines.map((wine, i) => {
			return <WineDescription key={i} wine={wine} />;
		});
	};
	return (
		<div id="wines" className="w-full overflow-hidden">
			<WineSelector
				wines={wines}
				selectedWine={selectedWine}
				setSelectedWine={setSelectedWine}
			/>
			<div className="bg-black relative text-white pb-10">
				<div className="h-full w-full absolute z-0 object-cover">
					<Image
						fluid={bg.childImageSharp.fluid}
						className="h-full w-full "
						imgStyle={{ objectFit: "cover" }}
					/>
				</div>
				<CarouselProvider
					isIntrinsicHeight
					totalSlides={wines.length}
					currentSlide={selectedWine}
					infinite
				>
					<WineCarouselInternals setCurrentSlide={setSelectedWine}>
						{renderWines()}
					</WineCarouselInternals>
				</CarouselProvider>
			</div>
		</div>
	);
};

/* Wine Selector
============================================================================= */

const WineSelector = ({ wines, selectedWine, setSelectedWine }) => {
	// Config

	// Render Wines
	const renderWines = () => {
		return wines.map((wine, i) => {
			return (
				<img
					src={wine.image}
					className="h-full cursor-pointer object-center"
					key={i}
					onClick={() => {
						setSelectedWine(i);
					}}
				></img>
			);
		});
	};

	// Render
	return (
		<div className="bg-white w-full md:flex flex-col items-center hidden">
			<h2
				style={{ fontFamily: "Crimson-Bold" }}
				className="text-center font-bold mt-12 orangetext text-5xl"
			>
				WINES
			</h2>
			<div className="bg-red-200 h-1 w-20 my-1 mx-auto orangecolour"></div>
			<div className="w-4/5 md:w-full lg:w-4/5 mb-16 h-80 my-12 flex flex-row items-center justify-around">
				{renderWines()}
			</div>
		</div>
	);
};

/* Wine Carousel Internals
============================================================================= */

const WineCarouselInternals = ({ children, setCurrentSlide, selectedWine }) => {
	// Config
	const carouselContext = useContext(CarouselContext);

	useEffect(() => {
		function onChange() {
			setCurrentSlide(carouselContext.state.currentSlide);
		}
		carouselContext.subscribe(onChange);
		return () => carouselContext.unsubscribe(onChange);
	}, [carouselContext]);

	return (
		<>
			<Slider className="h-full">{children}</Slider>
			<div className="md:pt-16 pt-8 md:pb-20 pb-8">
				<DotGroup />
			</div>
		</>
	);
};

/* Wine Description
============================================================================= */

const WineDescription = ({ wine }) => {
	// Config

	// Render
	return (
		<div className="container mx-auto md:px-20 px-10 relative z-0 flex flex-col lg:flex-row md:items-center items-start w-full md:pt-20 pt-10">
			<div className="h-96 md:h-180 mt-10 md:mt-30 w-1/2 mb-10 md:mb-0 hidden lg:block">
				<img
					className="mx-auto object-contain h-full"
					src={wine.image}
				></img>
			</div>
			<div className="w-full lg:w-1/2 text-white flex flex-col container">
				<div className="flex flex-row items-center justify-between">
					<h3
						style={{ fontFamily: "Crimson-Bold", zIndex: 100 }}
						className="w-1/2 md:text-5xl text-4xl orangetext relative"
					>
						{wine.title}
						<br /> {wine.year}
					</h3>
					<div
						className="h-72 md:h-96 mt-3 md:mt-30 w-1/2 mb-3 lg:hidden"
						style={{ zIndex: 10 }}
					>
						<img
							className="mx-auto object-contain h-full"
							src={wine.image}
						></img>
					</div>
				</div>
				<div className="text-lg md:text-xl w-full py-8">
					<p>{wine.description}</p>
				</div>
				<div className="text-lg md:text-xl w-full">
					<h4
						style={{ fontFamily: "Crimson-Bold" }}
						className="my-2 orangetext"
					>
						Movie Match
					</h4>
					<p>{wine.movieMatch}</p>
				</div>
				<div className="text-lg md:text-xl w-full my-4">
					<h4
						style={{ fontFamily: "Crimson-Bold" }}
						className="my-2 orangetext"
					>
						Book Match
					</h4>
					<p>{wine.bookMatch}</p>
				</div>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default Wines;
