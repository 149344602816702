import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Image from "gatsby-image";
import Facebook from "../svg/Facebook";
import Instagram from "../svg/Instagram";

/* Banner
============================================================================= */
const Banner = () => {
	const { banner } = useStaticQuery(graphql`
		query {
			banner: file(relativePath: { eq: "banner.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1500, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	return (
		<div className="w-full">
			<div className="h-32 md:h-full object-cover w-screen">
				<Image
					fluid={banner.childImageSharp.fluid}
					className="h-full w-full "
					imgStyle={{ objectFit: "cover" }}
				/>
			</div>
		</div>
	);
};

/* Export
============================================================================= */

export default Banner;
