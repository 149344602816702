import { Link } from "gatsby";
import {
	CarouselProvider,
	CarouselContext,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
	DotGroup,
} from "pure-react-carousel";
import React from "react";

/* About Carousel
============================================================================= */

const AboutCarousel = ({ children, isPlaying = false, interval = 5000 }) => {
	// Config

	// Render
	return (
		<CarouselProvider
			isIntrinsicHeight
			totalSlides={children.length}
			infinite
			isPlaying={isPlaying}
			interval={interval}
		>
			<Slider>{children}</Slider>
			<div className="pt-5">
				<DotGroup />
			</div>
		</CarouselProvider>
	);
};

/* Export
============================================================================= */

export default AboutCarousel;
